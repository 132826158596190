import * as Constants from "../components/Constants";

export const FILTER_STICKY_DURATION = 300000; // a user's search filter lasts this long, in ms (30000 = 5min)


export const INITIAL_NUTRITIONAL_FILTER_STATE = {
    lowCalorie: false,
    lowSodium: false,
    lowSugar: false,
    highFiber: false,
    highProtein: false,
    lowFat: false,
    lowCarbs: false,
};

export const FILTER_MAPPINGS = {
    lowCalorie: Constants.NINFO_PREFIX + "Calories<400",
    lowSodium: Constants.NINFO_PREFIX + "Sodium<200",
    lowSugar: Constants.NINFO_PREFIX + "Sugars<15",
    highFiber: Constants.NINFO_PREFIX + "Dietary_Fiber>5",
    highProtein: Constants.NINFO_PREFIX + "Protein>20",
    lowFat: Constants.NINFO_PREFIX + "Fat<10",
    lowCarbs: Constants.NINFO_PREFIX + "Carbohydrates<30",
};

export const WAITING_FOR_THUMBNAIL = "waiting_for_thumbnail",
    TIMED_OUT = "timed_out";

export const INITIAL_CATEGORIES = Constants.CATEGORIES.filter(
    (cat) => cat !== "none"
).map((cat) => ({
    label: cat,
    checked: true, // default to all items checked
}));


export const nfilters = [
    {label: "Low Calorie (<400kcal)", name: "lowCalorie"},
    {label: "Low Sodium (<200mg)", name: "lowSodium"},
    {label: "Low Sugar (<15g)", name: "lowSugar"},
    {label: "High Fiber (>5g)", name: "highFiber"},
    {label: "High Protein (>20g)", name: "highProtein"},
    {label: "Low Fat (<10g)", name: "lowFat"},
    {label: "Low Carb (<30g)", name: "lowCarbs"},
];

export const MAX_RETRIES_FOR_IMAGE_SUBMIT = 3; // Maximum number of retries
export const RETRY_DELAY = 2000; // Delay in milliseconds between retries

export const placeholder_ingredients = `1 ripe papaya, peeled and sliced,
2 eggs
1 tsp salt
1 tsp orange zest
`;

export const MAX_CHARS_PER_INPUT_LINE = 25; // Prevent user from very long ingredients