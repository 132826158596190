import React from "react";
import { Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";

//import assets
import modalX from "assets/icons/modal-x.svg";

export default function InfoModal({
  backdrop,
  toggle,
  onOpenFeedback,
  onClose,
}) {
  return (
    <Modal
      backdrop={backdrop}
      isOpen={true}
      toggle={toggle}
      className="modal-dialog"
    >
      <div className="modal-header">
        <div className="modal-header-flex">
          <h5 className="modal-header-text">Information</h5>
          <img
            className="modal-header-x"
            src={modalX}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          />
        </div>
      </div>
      <ModalBody>
        <div className="info-modal-content">
          <div className="info-modal-body-header">
            Kasserole is an online cookbook where you can:
          </div>
          <div>
            <ul className="info-modal-list">
              <li>Search, edit, and share recipes.</li>
              <li>
                Take a picture of a recipe in your favorite cookbook, and upload
                the picture. I&#39;ll scan the image, extract the recipe, and
                add it to your online cookbook.
              </li>
              <li>
                Type in a URL of an online recipe. I&#39;ll fetch the recipe and
                add it to your online cookbook.
              </li>
              <li>
                Type in a set of ingredients and I&#39;ll come up with a recipe,
                which you can add to your online cookbook.
              </li>
            </ul>
          </div>
        </div>
      </ModalBody>
      <div className="info-modal-footer">
        <div>
          <strong>Feedback? Suggestions? </strong>
          <span className="info-modal-link" onClick={() => onOpenFeedback()}>
            Click here
          </span>{" "}
          to share your thoughts.
        </div>
      </div>
    </Modal>
  );
}

InfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onOpenFeedback: PropTypes.func.isRequired,
  backdrop: PropTypes.bool,
  toggle: PropTypes.func,
};
