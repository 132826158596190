import React from "react";
import Navbar from "../../components/Navbar";
import homeImg from "../../assets/images/home_img.jpg";
import camera from "../../assets/icons/camera.svg";
import wandGrey from "../../assets/icons/wand.svg";
import wandWhite from "../../assets/icons/wand-white.svg";
import ModalWithAck from "../../components/ModalWithAck";
import {useCreateFromImage} from "./useCreateFromImage";
import "../../components/newRecipe.css";

export const CreateFromImage = () => {
    const {
        user,
        imageFileToUpload,
        acknowledgeInputModalIsActive,
        dismissModal,
        handleImageFileChange,
        submitImage,
        navigate,
        newImage,
    } = useCreateFromImage()

    return (user === null ? <div>Loading...</div> :
            <div className="app-container">
                <Navbar/>
                <div className="main-container">
                    <div className="recipe-hero-img-container">
                        <img className="recipe-hero-img" src={homeImg}/>
                        <div className="recipe-hero-img-overlay"></div>
                        <div className="recipe-hero-content">
                            <h5 className="recipe-hero-main-text">New Recipe</h5>
                            <div className="recipe-hero-subtext">
                                Take a picture of a page in a cookbook, <br></br>and I&apos;ll add
                                the recipe to your<br className="line-break"></br> online
                                cookbook.
                            </div>
                        </div>
                    </div>

                    <div className="form-container">
                        <label htmlFor="file-upload" className="custom-file-upload flex-center">
                            <img src={camera}/>
                            {imageFileToUpload == null ? "Upload Picture" : "Change Picture"}
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleImageFileChange}
                        />

                        {imageFileToUpload && (
                            <img src={newImage} alt="image" className="create-from-image-preview"/>
                        )}

                        <div className="new-recipe-disclaimer">
                            By default, all new recipes are public, meaning that all Kasserole users can see them.
                            If you want to make a recipe private, you can do so after it&apos;s created by editing that
                            recipe.
                        </div>

                        <div>
                            <div
                                style={{
                                    borderTop: "1px solid #E1E7ED",
                                    width: "100%",
                                    marginBottom: "24px",
                                }}
                            ></div>
                            <div
                                className={`create-recipe-btn${imageFileToUpload ? "" : "-disabled"}`}
                                onClick={() => imageFileToUpload && submitImage()}
                            >
                                <img src={imageFileToUpload ? wandWhite : wandGrey}/>
                                <div className="create-recipe-btn-text">Craft Recipe</div>
                            </div>
                        </div>
                    </div>
                </div>

                {acknowledgeInputModalIsActive && (
                    <ModalWithAck
                        valid={true}
                        title="I'm on it!"
                        message="Got it. I'll scan that image for a recipe, and add it to your cookbook."
                        onClick={() => {
                            dismissModal();
                            navigate("browse");
                        }}
                    />
                )}
            </div>
    );
}