import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../../components/Constants";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import * as LocalCache from "../../components/LocalCache";
import { callExternalApi } from "../../components/external-api.service";

export const useDetail = () => {
  const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { guid } = useParams();
  const navigate = useNavigate();
  const alreadyCalledLoadRecipeDetail = useRef(false);

  const encodedUser = Constants.encodeUser(user);
  const [actionsDropdownIsActive, setActionsDropdownIsActive] = useState(false);
  // recipe data

  const [recipe, setRecipe] = useState(null);

  const [userOwnsThisRecipe, setUserOwnsThisRecipe] = useState(false);

  // UI state
  const [shareModalIsActive, setShareModalIsActive] = useState(false);
  const [deleteModalIsActive, setDeleteModalIsActive] = useState(false);
  const [variantModalIsActive, setVariantModalIsActive] = useState(false);
  const [disclaimerModalIsActive, setDisclaimerModalIsActive] = useState(false);
  const [willCopyThisRecipeModalIsActive, setWillCopyThisRecipeModalIsActive] =
    useState(false);
  const [errorModalIsActive, setErrorModalIsActive] = useState(false);
  const [sharedFromModalIsActive, setSharedFromModalIsActive] = useState(false);
  const [mustNukeRecipe, setMustNukeRecipe] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorTitle, setErrorTitle] = useState(null);
  const [guidToEdit, setGuidToEdit] = useState(null);

  //functions
  const handleActionsClick = () => {
    setActionsDropdownIsActive(!actionsDropdownIsActive);
  };

  const loadRecipeDetail = async () => {
    if (alreadyCalledLoadRecipeDetail.current) {
      return;
    }
    const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
      getAccessTokenSilently,
      loginWithRedirect,
      navigate
    );
    const encodedUser = Constants.encodeUser(user);
    const url = Constants.generateSecureApiUrl(
      Constants.APIG_GET_RESOURCE,
      `user=${encodedUser}&guid=${guid}`
    );
    const { data } = await callExternalApi(url, accessToken);
    alreadyCalledLoadRecipeDetail.current = true;

    const error = handleError(data);
    if (error) {
      setErrorTitle(error.title);
      setErrorMessage(error.message);
      setErrorModalIsActive(true);
      if (error.title === "Couldn't create recipe") {
        setMustNukeRecipe(true);
      }
      return;
    }

    const r2 = Constants.trueUpRecipe(data);
    setRecipe(r2);
    setUserOwnsThisRecipe(r2.userid === encodedUser);

    if (r2.vc === 0 && r2.sf) {
      setSharedFromModalIsActive(true);
    }
  };

  const delete_recipe = async () => {
    LocalCache.add(guid, LocalCache.DELETE_STATUS);
    const url = Constants.generateSecureApiUrl(
      Constants.APIG_DELETE_RESOURCE,
      "guid=" + guid + "&user=" + encodedUser
    );
    const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
      getAccessTokenSilently,
      loginWithRedirect,
      navigate
    );
    callExternalApi(url, accessToken);
  };

  function generateSharedRecipeMessage(sharer) {
    return sharer + " has shared this recipe with you!";
  }

  const triggerIndexCleanupForThisUser = async () => {
    console.log(
      "User clicked on a non-existent recipe. Thus, the index is out of sync with the database. Triggering cleanup..."
    );
    const url = Constants.generateSecureApiUrl(
      Constants.APIG_CLEAN_RESOURCE,
      "mode=hourly"
    );
    const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
      getAccessTokenSilently,
      loginWithRedirect,
      navigate
    );
    callExternalApi(url, accessToken);
  };

  function navigateToEdit(g) {
    navigate(Constants.ROUTE_EDIT + g);
  }

  const prepareToEdit = async () => {
    // if user owns the recipe, simply redirect to the 'Edit' component
    if (userOwnsThisRecipe) {
      setGuidToEdit(guid);
      console.log("Setting guid to edit to " + guid);
      navigateToEdit(guid);
      return;
    }

    // if user doesn't own the recipe, pop up a modal explaining that we'll make a copy of the recipe
    setWillCopyThisRecipeModalIsActive(true);

    // ask back end to create a copy of this recipe, with this user as the owner, then redirect to Edit component
    // TODO: have the modal wait until the below is done (or 5s, whichever comes first)
    const new_guid = Constants.generateGUID();
    setGuidToEdit(new_guid);
    const url = Constants.generateSecureApiUrl(
      Constants.APIG_COPY_RESOURCE,
      "&old_guid=" + guid + "&new_guid=" + new_guid + "&user=" + encodedUser
    );
    const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
      getAccessTokenSilently,
      loginWithRedirect,
      navigate
    );
    console.log("** Invoking url = " + url);
    callExternalApi(url, accessToken);
  };

  const handleError = (data) => {
    if (data == null || data.recipe_name == null) {
      return {
        title: "Network Error",
        message: "Network error - cannot reach server.",
      };
    }

    if (data.recipe_name === "**ITEM NOT FOUND**") {
      triggerIndexCleanupForThisUser();
      return {
        title: "Recipe not Found",
        message: "That recipe does not exist or has recently been deleted.",
      };
    }

    if (data.error) {
      return {
        title: "Couldn't create recipe",
        message: `Failed to create this recipe. Error message: ${data.error}`,
      };
    }

    return null;
  };

  //effects
  useEffect(() => {
    window.scrollTo(0, 0);
    // Reset state on unmount
    return () => {
      setShareModalIsActive(false);
      setDeleteModalIsActive(false);
      setVariantModalIsActive(false);
      setDisclaimerModalIsActive(false);
      setErrorModalIsActive(false);
      setWillCopyThisRecipeModalIsActive(false);
    };
  }, []);

  useEffect(() => {
    if (mustNukeRecipe) {
      console.log("Nuking recipe");
      setMustNukeRecipe(false);
      delete_recipe();
    }
  }, [mustNukeRecipe]); // Dependency array ensures this runs only when mustNukeRecipe changes

  useEffect(() => {
    let isMounted = true;
    const loadDataWrapper = async () => {
      await loadRecipeDetail();
      if (!isMounted) {
        return;
      }
    };
    loadDataWrapper();
    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  
  const getNinfoObj = (text) => {
  
    const lines = text.split("\n");
    const obj = {};
  
    lines.forEach((line) => {
      if (line.trim()) { // Skip empty lines
        const [key, value] = line.split(/\s*:\s*/); // Split on colon with flexible spacing
        if (key && value) {
          obj[key.trim().replace(/['"]+/g, "")] = value.trim().replace(/['"]+/g, ""); // Remove quotes
        }
      }
    });
  
    return obj;
  };
  
  

  const createNinfoObj = (recipe) => {
    const nutritionalInfo = recipe.ninfo.split("\n");
    const returnObj = {};

    nutritionalInfo.forEach((line) => {
      const [label, value] = line.split(":");
      if (value)
        returnObj[label.toLowerCase().trim()] = parseInt(
          value.replace("~", "").trim()
        );
    });
    return returnObj;
  };

  const getIngredients = (recipeBody) => {
    if (recipeBody.includes("Ingredients")) {
      return recipeBody
        .split("\n\n")
        .filter((ingredient) => ingredient.includes("Ingredients"))[0]
        .split("\n")
        .slice(1)
        .map((ingredient) => ingredient.replace(/^- /, ""));
    } else return [];
  };

  return {
    guid,
    handleActionsClick,
    recipe,
    actionsDropdownIsActive,
    errorModalIsActive,
    errorTitle,
    errorMessage,
    setErrorModalIsActive,
    navigate,
    setVariantModalIsActive,
    setShareModalIsActive,
    prepareToEdit,
    userOwnsThisRecipe,
    setDeleteModalIsActive,
    setDisclaimerModalIsActive,
    shareModalIsActive,
    deleteModalIsActive,
    variantModalIsActive,
    willCopyThisRecipeModalIsActive,
    setWillCopyThisRecipeModalIsActive,
    navigateToEdit,
    guidToEdit,
    disclaimerModalIsActive,
    sharedFromModalIsActive,
    setSharedFromModalIsActive,
    generateSharedRecipeMessage,
    getNinfoObj,
    createNinfoObj,
    getIngredients,
  };
};
